// font stack
$system-font-stack: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', Osaka, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
$global-font-stack: 'Nunito', $system-font-stack;
$global-font-size: 20px;
$mobile-font-size: 16px;
$global-line-height: 1.36;

$header-font-size: 1.3rem;
$header-font-weight: 900;

// measurements
$grid-gutter: 16px;
$wrapper-padding: 12px;
$wrapper-width: 1208px;
$tablet-wrapper-width: 820px;
$mobile-wrapper-width: 360px;

// colors brand colors
// using css variables for these so I can see whats used when debugging
:root {
  --colors-yellow-light: #FFFF93;
  --colors-yellow: #FEFF00;
  --colors-yellow-dark: #FCE902;

  --colors-lime-light: #E9FF52;
  --colors-lime: #E9FF52;
  --colors-lime-dark: #C3ED00;

  --colors-green-light: #C6FFCD;
  --colors-green: #85FF7A;
  --colors-green-dark: #17C501;

  --colors-blue-light: #B8FFFF;
  --colors-blue: #03FFFF;
  --colors-blue-dark: #00CCCC;
  --colors-blue-alt: #3A9FC7;

  --colors-purple-light: #747AC2;
  --colors-purple: #524A99;
  --colors-purple-dark: #503B7C;

  --colors-pink-light: #FFCAEE;
  --colors-pink: #FFA2DC;
  --colors-pink-dark: #FF65CC;

  --colors-red-light: #FFD2CD;
  --colors-red: #FF8FA1;
  --colors-red-dark: #FF6353;

  --colors-orange-light: #FFD185;
  --colors-orange: #FFA900;
  --colors-orange-dark: #FF9601;
}

$colors-yellow-light: var(--colors-yellow-light);
$colors-yellow: var(--colors-yellow);
$colors-yellow-dark: var(--colors-yellow-dark);

$colors-lime-light: var(--colors-lime-light);
$colors-lime: var(--colors-lime);
$colors-lime-dark: var(--colors-lime-dark);

$colors-green-light: var(--colors-green-light);
$colors-green: var(--colors-green);
$colors-green-dark: var(--colors-green-dark);

$colors-blue-light: var(--colors-blue-light);
$colors-blue: var(--colors-blue);
$colors-blue-dark: var(--colors-blue-dark);
$colors-blue-alt: var(--colors-blue-alt);

$colors-purple-light: var(--colors-purple-light);
$colors-purple: var(--colors-purple);
$colors-purple-dark: var(--colors-purple-dark);

$colors-pink-light: var(--colors-pink-light);
$colors-pink: var(--colors-pink);
$colors-pink-dark: var(--colors-pink-dark);

$colors-red-light: var(--colors-red-light);
$colors-red: var(--colors-red);
$colors-red-dark: var(--colors-red-dark);

$colors-orange-light: var(--colors-orange-light);
$colors-orange: var(--colors-orange);
$colors-orange-dark: var(--colors-orange-dark);

// text colors
$color-text: #141414;
$color-text-alt: #35325a;
$color-text-invert: #faf7ff;
$color-text-button: #f0f6b6;

// background colors
$background-panel: #fff;
$background-panel-translucent: rgba($background-panel, .68);
$background-panel-invert: $colors-purple-light;
$background-panel-invert-translucent: #676BAA;
$background-button: $colors-purple-dark;
$background-features: $colors-purple-dark;

// third party brand colors
$brand-twitter: #1DA1F2;
$brand-instagram: #E4405F;
$brand-facebook: #1877F2;
$brand-discord: #7289DA;
$brand-twitch: #9146FF;
$brand-youtube: #ff0000;
$brand-reddit: #FF4500;