@use 'sass:math';

@mixin painting-grid-limit-items-per-row($n) {
  .PaintingGrid__item {
    max-width: math.div(100%, $n);
  }
  
  .PaintingGrid__break {
    visibility: collapse;
  }
  
  .PaintingGrid__break:nth-child(#{$n * 2}n) {
    visibility: visible;
    flex-basis: 100%;
  }
}

@mixin painting-grid-limit-items-shown($n) {
  .PaintingGrid__item:nth-child(n + #{$n * 2 + 1}) {
    display: none;
  }
}

.PaintingGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -6px;
  // @include painting-grid-limit-items-per-row(4);
}

.PaintingGrid__item {
  width: auto;
  height: 90px;
  margin: 6px;

  img {
    box-shadow: 0 3px 6px rgba(#9d8769, .62);
    height: 100%;
  }

  @include breakpoint-desktop {
    height: 110px;
  }
}

.PaintingGrid__break {
  visibility: collapse;
}