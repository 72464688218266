@use 'sass:math';

$grid-gutter: 16px !default;

// use like grid-span(1 / 4) for a one-quarter column
@function grid-span($span) { 
  @return 100% * $span;
}

// use for elements that act as wrappers for columns
@mixin grid-row() {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: - math.div($grid-gutter, 2);
  margin-left: - math.div($grid-gutter, 2);
}

// flip an existing row's direction
@mixin grid-row-reverse() {
  flex-direction: row-reverse;
}

// use for elements that act as columns
@mixin grid-column($span: 0) {
  box-sizing: border-box; 
  flex: 0 0 auto;
  padding-right: math.div($grid-gutter, 2);
  padding-left: math.div($grid-gutter, 2);
  @if ($span != 0) {
    @include grid-column-width($span);
  }
}

// set column element width
@mixin grid-column-width($span) {
  flex-basis: grid-span($span);
  max-width: grid-span($span);
}

// push column element to the right
@mixin grid-column-offset-left($span) {
  margin-left: grid-span($span);
}

// push column element to the left
@mixin grid-column-offset-right($span) {
  margin-right: grid-span($span);
}