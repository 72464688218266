@use 'sass:math';

.Product {
  height: 100%;
  display: flex;
  flex-direction: column;

  &.Product--physical {
    padding-top: 230px;
    @include breakpoint-tablet {
      padding-top: 0;
    }
  }
}

.ProductImage {
  overflow: hidden;
  border-radius: 24px;
  margin-bottom: 16px;
  border: 1px solid white;

  @include breakpoint-tablet {
    height: 130px; // TODO: do some aspect ratio hack instead?
  }

  @include breakpoint-desktop {
    height: 195px; // TODO: do some aspect ratio hack instead?
  }
}

.ProductImage {
  .Product--physical & {
    border: 0;
  }
}

.ProductCard {
  flex: 1;
  border-radius: 24px;
  background: $background-panel-translucent;
  color: $color-text;
  transform: rotateZ(.25deg);
  position: relative;
}

.ProductCard {
  .Product--physical & {

    margin-top: -120px;
    padding-top: 120px;

    @include breakpoint-tablet {
      margin-bottom: 24px;
    }

    @include breakpoint-desktop {
      margin-top: -180px;
      padding-top: 180px;
    }
  }
}

.ProductCard__inner {
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  transform: rotateZ(-.25deg);
}

.ProductCard__floatingImage {
  position: absolute;
  width: 150px;
  top: -240px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 0;
  z-index: 1;

  @include breakpoint-desktop {
    width: 186px;
    top: -290px;
  }
}

.Product--physical .ProductCard {
  transform: rotateZ(.25deg);
  .ProductCard__inner {
    transform: rotateZ(-.25deg);
  }
}

.Product--basic .ProductCard {
  transform: rotateZ(-.25deg);
  .ProductCard__inner {
    transform: rotateZ(.25deg);
  }
}

.Product--patron .ProductCard {
  transform: rotateZ(.3deg);
  .ProductCard__inner {
    transform: rotateZ(-.3deg);
  }
}

.ProductCard__title {
  font-size: $header-font-size * .9;
  font-weight: 900;
  margin-bottom: .125em;
  color: $colors-orange-dark;

  .Product--physical & {
    color: $colors-purple-light;
  }

  @include breakpoint-desktop {
    font-size: $header-font-size;
  }
}

.ProductCard__price {
  color: $colors-purple-dark;
  flex: 1;
  font-weight: 900;
  font-size: $header-font-size * .9;

  @include breakpoint-desktop {
    font-size: $header-font-size;
  }
}

.ProductCard__priceCurrency {
  font-size: 1rem;
  margin-right: .25ch;
}

.ProductCard__priceAccess {
  font-size: 1rem;
}

.ProductCard__quote {
  margin: .5em 0;
  font-style: italic;

  @include breakpoint-tablet {
    min-height: 5rem;
  }

  @include breakpoint-desktop {
    min-height: 4rem;
  }
}

.ProductCard__contentsList {
  // margin-top: .5em;
  margin-left: 1.5ch;
  margin-bottom: 1.5em;

  li {
    margin-bottom: .25em;
    position: relative;

    &::before  {
      background: svg-load('decoration/list_star_dark.svg');
      display: block;
      content: ' ';
      width: 15px;
      height: 15px;
      position: absolute;
      left: -20px;
      top: 5px;
    }
  }
}

.ProductCard__purchase {
  margin-top: auto;
  margin-left: -16px;
  margin-bottom: -16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.ProductCard__button {
  margin-left: 16px;
  margin-bottom: 16px;
  min-height: 2em;
  background: $colors-orange-dark;

  .Product--physical & {
    background: $colors-purple-light;
  }
}

.ProductCard__shipping {
  margin-top: 1em;
  font-size: .75em;
}