@use 'sass:math';

.Trailer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 4rem;
  @include wrapper;

  @include breakpoint-tablet {
    margin-top: 4rem;
    margin-bottom: 8rem;
  }
}

.Trailer__container {
  @include grid-row;
}

.Trailer__embedWrapper {
  position: relative;
  @include grid-column(math.div(1, 1));

  @include breakpoint-tablet { 
    @include grid-column(math.div(10, 18));
    @include grid-column-offset-left(math.div(4, 18));
    @include grid-column-offset-right(math.div(4, 18));
  }
}

.Trailer__sparkle {
  position: absolute;
  width: 20px;
  top: -40px;
  left: -15px;

  @include breakpoint-tablet {
    width: 20px;
    top: -10px;
    left: -50px;
  }
}

.Trailer__forceAspect {
  padding-bottom: math.div(9, 16) * 100%;
  position: relative;
  margin-bottom: 1em;
}

.Trailer__embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.PressHype {
  display: flex;
  justify-content: center;
  width: 100%;
  color: $colors-blue;
  margin-top: 1em;
}

.PressHype__item {
  width: 17ch;
  text-align: center;
  font-weight: bolder;
  position: relative;

  // display stars before and after each review

  &:before,
  &:last-child:after {
    width: 18px;
    height: 18px;
    display: block;
    content: ' ';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    background-image: svg-load('./decoration/press_star.svg');
    background-size: 100%;
    background-repeat: no-repeat;
  }

  &:last-child:after {
    left: initial;
    right: 0;
    transform: translate(50%, -50%);
  }

  // hide first and last stars on phone only
  @include breakpoint-phone-only {
    &:first-child:before,
    &:last-child:after {
      display: none;
    }
  }
}

.PressHype__score {
  font-size: 1.5em;
  font-weight: 900;
  // margin-bottom: .25rem;
}

.PressHype__outlet {
  // font-weight: 900;
  font-size: 1em;
}