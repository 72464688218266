@use 'sass:math';
@import 'node_modules/swiper/swiper.scss';

.Slideshow {
  display: flex;
  flex-wrap: wrap;
  @include user-select(none);
}

.swiper-container {
  margin-top: 16px;
  flex: 1;
  flex-basis: 100%;
  width: 0;
}

.swiper-slide {
  cursor: pointer;
}

.Slideshow__nav {
  display: flex;
  flex-basis: 100%;
  width: 100%;
  padding: 16px;
  justify-content: center;
  align-items: center;
}

.Slideshow__pagination {
  // flex: 1;
  display: flex;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  margin-right: 16px;
  border-radius: 50%;
  background: rgba(255, 255, 255, .3);
  cursor: pointer;
}

.swiper-pagination-bullet:last-of-type {
  margin-right: 0;
}

.swiper-pagination-bullet-active {
  background: rgba(255, 255, 255, .6);
}

.Slideshow__button {
  cursor: pointer;
  svg {
    height: 34px;
  }
}

.Slideshow__button--prev {
  margin-right: 1em;
  margin-top: -3px;
}

.Slideshow__button--next {
  margin-left: 1em;
  margin-top: 3px;
}