@use 'sass:math';

.Footer {
  background: $colors-purple-dark;
  color: $colors-pink-light;
  font-weight: bold;
  position: relative;
  font-size: .8em;
}

.Footer__divider {
  background: svg-load('footer/divider.svg');
  background-size: 292px 16px; // attempt to fix a background gap issue twarda saw
  background-repeat: repeat-x;
  background-position: center;
  height: 16px;
  width: 100%;
  position: absolute;
  top: -10px;
  left: 0;
}

.Footer__wrapper {
  @include wrapper();
  padding-top: 3em;
}

.Footer__main {
  @include grid-row();
  @include breakpoint-tablet {
    align-items: center;
  }
  padding-bottom: 2em;
}

.FooterSection {
  @include grid-column(math.div(1, 1));
  // margin-bottom: 1em;
}

.FooterSection.FooterSection--ratings {
  @include grid-column(math.div(1, 1));

  @include breakpoint-tablet {
    @include grid-column(math.div(6, 18));
  }
}

.FooterSection.FooterSection--legal {
  @include grid-column(math.div(1, 1));
  text-align: center;

  p:not(:first-of-type) {
    margin-top: .5em;
  }

  @include breakpoint-tablet {
    // @include grid-column-offset-left(math.div(1, 18));
    // @include grid-column-offset-right(math.div(1, 18));
    @include grid-column(math.div(6, 18));
    // text-align: left;
  }
}

.FooterSection.FooterSection--guy {
  @include grid-column(math.div(1, 1));
  display: flex;
  justify-content: center;

  svg {
    height: 2.25em;
  }

  @include breakpoint-tablet {
    @include grid-column(math.div(6, 18));
    justify-content: flex-end;
    // margin-bottom: .5em;
  }
}

.FooterSection--links {
  @include grid-column(math.div(1, 1));
  text-align: center;
}

.FooterSection__link {
  color: inherit;
  margin: 0 .5ch;
}

.RatingList {
  display: grid;

  grid-template-columns: repeat(3, 42px);
  grid-gap: 16px;
  justify-content: center;
  align-items: center;

  .RatingList__item {
    width: auto !important;
  }

  @include breakpoint-tablet {
    justify-content: flex-start;
  }

  @include breakpoint-desktop {
    grid-template-columns: repeat(3, 58px);
    grid-gap: 32px;
  }
}