@use 'sass:math';

.ProductSection {
  position: relative;
  // can't help but feel like this looks considerably worse than the pink gradient, but ok.......
  background: linear-gradient(to bottom, #f8adcc, #FFBEA2, #ffcd83);
  padding-top: 86px; // height of the divider img
  // old pink gradient in case we backtrack
  // @include breakpoint-tablet {
  //   padding-bottom: 2em;
  //   background: 
  //     radial-gradient(ellipse at top, #FFBEA2, ease, transparent),
  //     linear-gradient(to top right, #FFCA74, ease-in-out, #FFB9F8);
  // }
}

.ProductSection__divider {
  position: absolute;
  background: svg-load('productsection/divider.svg');
  background-size: cover;
  background-position: center;
  height: 86px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ProductSection__head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 6em;
  padding-bottom: 4em;

  @include breakpoint-tablet { 
    padding-bottom: 8em;
    flex-direction: row;
  }

  @include breakpoint-desktop { 
    flex-direction: row;
  }
}

.ProductSection__intro {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;

  @include breakpoint-tablet { 
    text-align: left;
  }
}

.ProductSection__introGuy {
  width: 3em;
  margin-right: 1.25em;
  margin-bottom: -1%;
  display: none;

  @include breakpoint-tablet { 
    display: block;
  }
}

.ProductSection__introText {
  display: flex-inline;
  flex-direction: column;
  justify-content: center;

  @include breakpoint-tablet { 
    margin-right: -4em;
    display: block;
    // justify-content: flex-start;
  }
}

.ProductSection__title {
  display: inline-block;
  text-align: center;
  width: auto;
  text-align: center;
  font-size: $global-font-size;
  // font-size: $header-font-size;
  font-weight: $header-font-weight;
  color: #f8febf;
  background: $colors-purple;
  border-radius: 40px;
  margin-bottom: .1em;
  padding: .3em 1em;

  @include breakpoint-tablet { 
    text-align: left;
  }

  @include breakpoint-desktop { 
    font-size: $global-font-size * 1.1;
  }
}

.ProductSection__subtitle {
  text-align: center;
  font-size: $global-font-size;
  // font-size: $global-font-size;
  font-weight: $header-font-weight;
  max-width: 35ch;
  color: $colors-purple-dark;
  margin-top: .5em;
  margin-left: .75em;
  max-width: 30ch;

  @include breakpoint-tablet { 
    text-align: left;
  }

  @include breakpoint-desktop { 
    font-size: $global-font-size * 1.1;
  }
}

.ProductSection__compatibleWith {
  width: 300px;

  @include breakpoint-tablet { 
    margin-top: -1em;
  }

  @include breakpoint-desktop {
    margin-top: -1em;
    width: 377px;
  }
}

.ProductSection__body {
  @include wrapper;
}

// $grid-gutter: 1em;

.ProductSection__grid {
  @include grid-row;
}

.ProductSection__item {
  @include grid-column(math.div(1, 1));
  margin-top: $grid-gutter;

  @include breakpoint-tablet {
    @include grid-column(math.div(6, 18));
  }

  &.ProductSection__item--galleryPreview {
    @include grid-column(math.div(1, 1));
  }
}

// $grid-gutter: 1em;

.SonarpenCardWrapper {
  @include grid-row;
  margin-top: 4em;
  width: 100%;
  justify-content: center;
}

.SonarpenCard {
  @include grid-column(math.div(16, 18));
  border-radius: 24px;
  background: $background-panel-translucent;
  color: $color-text;
  transform: rotateZ(3deg);
  position: relative;

  @include breakpoint-tablet {
    @include grid-column(math.div(8, 18));
  }

  @include breakpoint-desktop {
    @include grid-column(math.div(6, 18));
  }
}

.SonarpenCard__inner {
  padding: 32px;
  transform: rotateZ(-3deg);
}

.SonarpenCard__title {
  color: $colors-purple;
  font-weight: $header-font-weight;
  margin-bottom: .4em;
}

.SonarpenCard__subtitle {
  color: $colors-purple-light;
  font-weight: $header-font-weight;
  font-size: 1.025em;
  display: inline-block;
  max-width: 25ch;
}

.SonarpenCard__imageWrapper {
  position: relative;
  height: 100px;
  @include breakpoint-tablet {
    height: 140px;
  }
}

.SonarpenCard__image {
  position: absolute;
  width: auto;
  height: auto;
  top: 25px;
  left: -100px;
  max-width: 440px;
  max-height: 95px;
  transform: rotate(-12deg);

  @include breakpoint-tablet {
    top: 35px;
    left: -140px;
    max-width: 560px;
    max-height: 95px;
    transform: rotate(-15deg);
  }

  @include breakpoint-desktop {
    top: 30px;
    left: -170px;
    max-width: 660px;
    max-height: 95px;
    transform: rotate(-12deg);
  }
}

.SonarpenCard__foot {
  display: flex;
  justify-content: flex-end;
}

.SonarpenCard__button {
  background: $colors-purple-light;
}