@use 'sass:math';

.Hero {
  background-image: radial-gradient(
      circle at center left,
      #ffd0af,
      transparent 50%
    ),
    linear-gradient(to top right, #938bf5, #7dcbf1);
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  z-index: 0;
  will-change: contents;
  position: relative;

  height: 100vh;
  // clamp height
  min-height: 760px;
  max-height: 1200px;

  @include breakpoint-tablet {
    // clamp height
    min-height: 900px;
    max-height: 1200px;
  }
}

.Hero__head {
  display: flex;
}

.Hero__galleryButton {
  color: $colors-purple;
  display: block;
  margin-left: auto;
  margin-top: 20px;
  margin-right: 20px;
  font-weight: 900;
  z-index: 100;

  @include breakpoint-desktop {
    margin: 0;
    position: absolute;
    top: 30px;
    right: 30px;
  }
}

.Hero__galleryButtonInner {
  display: inline-flex;
  align-items: center;
}

.Hero__galleryButtonIcon {
  width: 1.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.Hero__main {
  @include wrapper;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.Hero__splash {
  padding-bottom: 120px;
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  @include breakpoint-tablet {
    margin-top: 40px;
    flex-direction: row;
    @include grid-column-offset-left(math.div(3, 18));
    @include grid-column-offset-right(math.div(1, 18));
  }
}

.Hero__image {
  z-index: 6;
  width: 100%;
  max-width: 500px;

  @include breakpoint-tablet {
    margin-left: -40px;
    margin-top: -40px;
    max-width: 750px;
  }

  @include breakpoint-desktop {
    margin-left: -60px;
    margin-top: -80px;
    max-width: 750px;
  }
}

.Hero__intro {
  display: flex;
  z-index: 6;
  flex-direction: column;
  align-items: center;

  @include breakpoint-tablet {
    margin-left: -300px;
    margin-right: 10px;
    margin-top: -50px;
  }

  @include breakpoint-desktop {
    margin-left: -380px;
    margin-right: 100px;
    margin-top: -60px;
  }
}

.Hero__logo {
  width: 220px;
  // margin-left: -7em;
  margin-bottom: 0.25rem;

  @include breakpoint-tablet {
    width: 240px;
  }
}

.Hero__tagline {
  color: $colors-purple-dark;
  margin-bottom: 1rem;
  text-align: center;
  max-width: 25ch;
  text-shadow: 0px 0px 28px white;
  position: relative;
}

.Hero__sparkle1 {
  position: absolute;
  top: -100px;
  right: -30px;
  width: 50px;
  z-index: -1;
  @include parallax-translate(5);

  @include breakpoint-tablet {
    top: -80px;
    right: -200px;
    width: 70px;
  }
}

.Hero__button {
  // margin-left: -2em;
  display: inline-block;
  color: #fff8c3;
  background: $colors-orange-dark;
  box-shadow: 0px 0px 32px -4px white;
}

.Hero__sparkle2 {
  position: absolute;
  left: -50px;
  top: 30%;
  width: 100px;
  z-index: -1;
  @include parallax-translate(5);

  @include breakpoint-tablet {
    left: -190px;
    top: 40%;
    width: 150px;
  }
}

.Hero__sparkle3 {
  position: absolute;
  right: -30px;
  bottom: 40%;
  width: 30px;
  z-index: -5;
  @include parallax-translate(5);

  @include breakpoint-tablet {
    right: 0px;
    bottom: 30%;
    width: 36px;
  }
}

$foot-height: 60px;

.Hero__foot {
  background: $colors-purple-dark;
  height: $foot-height;
  display: flex;
  justify-content: center;
}

.Hero__gfxContainer {
  z-index: -6;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;

  @include breakpoint-phone-only {
    // left and right are offset on mobile to try to keep the castle and colors dudes in view
    left: 30%;
    right: -30%;
  }

  // @include breakpoint-tablet {
  //   left: 0;
  //   right: 0;
  // }
}

.Hero__gfx {
  position: absolute;
  bottom: -2px;
  // bottom: $foot-height - 2px; // overlap to prevent very thin gap
  max-width: initial;
}

.Hero__gfx--1 {
  z-index: 5;
}

.Hero__gfx--2 {
  z-index: 4;
  @include parallax-translate(1);
}

.Hero__gfx--3 {
  z-index: 3;
  @include parallax-translate(2);
}

.Hero__gfx--4 {
  z-index: 2;
  @include parallax-translate(3);
}
