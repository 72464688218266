@charset 'utf-8';

@import './vars';
@import './mixins/index';

@import './parts/reset';


html, body {
  font-family: $global-font-stack;
  font-size: $mobile-font-size;
  line-height: $global-line-height;
  overflow-x: hidden;

  @include breakpoint-desktop {
    font-size: $global-font-size;
  }
}

img, svg {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  cursor: pointer;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  font-size: $header-font-size;
  font-weight: $header-font-weight;
}

@import './parts/button';

@import './parts/hero';

@import './parts/trailer';

@import './parts/features';

@import './parts/product-section';

@import './parts/product-card';

@import './parts/painting-grid';

@import './parts/gallery-preview';

@import './parts/slideshow';

@import './parts/footer';

// @import './parts/discord-widget';