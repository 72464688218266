@use 'sass:math';

.GalleryCard {
  margin-bottom: 4em;

  @include breakpoint-tablet {
    margin-top: 6em;
    margin-bottom: 6em;
  }
}

.GalleryCard__inner {
  // padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  // transform: rotateZ(.5deg);
  @include grid-row();
  align-items: center;
}

.GalleryCard__head {
  @include grid-column(math.div(1, 1));
  text-align: center;
  min-height: 16em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include breakpoint-tablet {
    @include grid-column(math.div(5, 18));
    @include grid-column-offset-left(math.div(.5, 18));
    @include grid-column-offset-right(math.div(.5, 18));
    min-height: initial;
    display: block;
  }
}

.GalleryCard__body {
  @include grid-column(math.div(1, 1));

  @include breakpoint-phone-only {
    .PaintingGrid {
      @include painting-grid-limit-items-per-row(2);
      @include painting-grid-limit-items-shown(8); 
    }
  }

  @include breakpoint-tablet-only {
    @include grid-column(math.div(12, 18));
    .PaintingGrid {
      @include painting-grid-limit-items-per-row(3);
    }
  }

  @include breakpoint-desktop {
    @include grid-column(math.div(12, 18));

    .PaintingGrid {
      @include painting-grid-limit-items-per-row(4);    
    }
  }
}

.GalleryCard__title {
  color: $colors-purple-dark;
  margin: 0 auto;
  max-width: 25ch;
  font-weight: 900;
  font-size: 1.2em;
  margin-top: .5em;
  margin-bottom: 1em;
}

.GalleryCard__callToAction {
  width: auto;
  display: inline-flex;
  justify-items: center;
  background: $colors-purple-dark;
}

.GalleryCard__buttonIcon {
  display: inline-block;
  width: 1.5em;
  margin-left: .5em;
}