@use 'sass:math';

.FeatureSection {
  background: $background-features;
  color: $color-text-invert;
  padding: 2em 0;
  // overlap hero elements
  position: relative;
  z-index: 0;
}

.FeatureSection__scrollHint {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #f1fab8;
  font-weight: 900;
}

.FeatureSection__scrollHintIcon {
  margin-right: 0.75em;
  width: 32px;
}

.FeatureSection__scrollHintGuy {
  margin-left: 0.75em;
  width: 28px;
}

.FeatureSection__wrapper {
  @include wrapper;
}

.FeatureBlock {
  @include grid-row;
  margin-bottom: 3em;

  h3 {
    color: $colors-green-light;
    margin-bottom: 0.75em;
  }

  p,
  ul {
    color: $colors-yellow-light;
    font-weight: 600;
  }

  @include breakpoint-tablet {
    margin-bottom: 8em;
  }
}

.FeatureBlock:last-of-type {
  margin-bottom: 4em;
}

.FeatureBlock--paintingFeatures {
  align-items: center;
}

.PaintingFeatures__image {
  margin-top: 1em;

  @include grid-column(math.div(1, 1));

  @include breakpoint-tablet {
    @include grid-column(math.div(8, 18));
  }

  @include breakpoint-desktop {
    @include grid-column(math.div(7.5, 18));
    @include grid-column-offset-left(math.div(1, 18));
  }
}

$paintingFeatures__info-column-span: 7.5;

.PaintingFeatures__info {
  @include grid-column(math.div(1, 1));

  @include breakpoint-phone-only {
    margin-top: 2rem;
  }

  @include breakpoint-tablet {
    @include grid-column(math.div(9, 18));
    @include grid-column-offset-left(math.div(1, 18));
  }

  @include breakpoint-desktop {
    @include grid-column(math.div($paintingFeatures__info-column-span, 18));
    @include grid-column-offset-left(math.div(0.75, 18));
  }
}

.PaintingFeatures__infoBox {
  background-color: $background-panel-invert-translucent;
  border-radius: 24px;

  ul {
    margin-left: 1.5ch;
  }

  li {
    margin-bottom: 0.5em;
    position: relative;

    &::before {
      background: svg-load("decoration/list_star_light.svg");
      display: block;
      content: " ";
      width: 15px;
      height: 15px;
      position: absolute;
      left: -20px;
      top: 5px;
    }
  }

  @include breakpoint-tablet {
    transform: rotate(-2deg);
  }
}

.PaintingFeatures__infoBoxInner {
  padding: 24px;
  position: relative;

  @include breakpoint-tablet {
    padding: 0;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    margin-left: grid-span(math.div(0.75, $paintingFeatures__info-column-span));
    margin-right: grid-span(math.div(0.75, $paintingFeatures__info-column-span));
    transform: rotate(2deg);
  }
}

.PaintingFeatures__sparkle {
  position: absolute;
  top: -40px;
  right: -60px;
  height: 30px;

  @include breakpoint-tablet {
    top: -20px;
    right: -120px;
    height: 40px;
  }
}

.Sonarpen__header {
  margin: 0;
  @include grid-column(math.div(1, 1));

  @include breakpoint-tablet {
    margin: 0;
    @include grid-column(math.div(7, 18));
    @include grid-column-offset-left(math.div(2, 18));
  }
}

.Sonarpen__info {
  margin: 0.5rem 0;
  @include grid-column(math.div(1, 1));

  @include breakpoint-tablet {
    margin: 0;
    @include grid-column(math.div(6, 18));
    @include grid-column-offset-left(math.div(1, 18));
    @include grid-column-offset-right(math.div(2, 18));
  }
}

.Sonarpen__imageWrapper {
  @include grid-column(math.div(1, 1));
  margin-top: 2em;
  position: relative;
  @include breakpoint-tablet {
    @include grid-column(math.div(15, 18));
    @include grid-column-offset-left(math.div(1.5, 18));
    @include grid-column-offset-right(math.div(1.5, 18));
  }
}

.Sonarpen__sparkle {
  position: absolute;
  bottom: -30px;
  left: -80px;
  height: 50px;

  @include breakpoint-tablet {
    bottom: -60px;
    left: -80px;
    height: 80px;
  }
}

.Sonarpen__image {
  background-color: $colors-orange-light;
  border-radius: 24px;
  height: 140px;
  transform: rotate(1deg);
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 1rem;
  // overflow: hidden;

  @include breakpoint-tablet {
    height: 160px;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  @include breakpoint-desktop {
    height: 200px;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  img {
    height: 140px;
    width: initial;
    max-width: initial;
    // margin-left: 20px;
    margin-top: -20px;

    @include breakpoint-tablet {
      margin-top: 20px;
      height: 220px;
      // transform: rotate(-30deg);
    }

    @include breakpoint-desktop {
      height: 280px;
      margin-left: 20px;
      margin-top: 40px;
      // transform: rotate(-30deg);
    }
  }
}

.FeatureBlock.FeatureBlock--quest {
  align-items: center;

  @include breakpoint-tablet {
  }
}

.Quest__image {
  margin-bottom: 2rem;
  @include grid-column(math.div(1, 1));
  @include breakpoint-tablet {
    margin-bottom: 0;
    @include grid-column(math.div(7, 18));
    @include grid-column-offset-left(math.div(2, 18));
  }
}

.Quest__info {
  margin: 0.5rem 0;
  @include grid-column(math.div(1, 1));
  @include breakpoint-tablet {
    margin: 0;
    @include grid-column(math.div(6, 18));
    @include grid-column-offset-left(math.div(1, 18));
    @include grid-column-offset-right(math.div(1, 18));
  }
}

$galleryFeatures-column-span: 16;

.FeatureBlock.FeatureBlock--gallery {
  @include grid-column(math.div(1, 1));
  @include breakpoint-tablet {
    @include grid-column($galleryFeatures-column-span);
    @include grid-column-offset-left(math.div(1, 18));
    @include grid-column-offset-right(math.div(1, 18));
  }
}

.Gallery__box {
  position: relative;
  background: $background-panel-invert-translucent;
  border-radius: 24px;
  transform: rotate(1deg);
}

.Gallery__boxInner {
  @include grid-row;
  padding: 24px;
  align-items: center;
  transform: rotate(-1deg);

  @include breakpoint-tablet {
    padding: 0;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}

.Gallery__sparkle {
  position: absolute;
  bottom: -40px;
  right: -30px;
  height: 35px;

  @include breakpoint-tablet {
    bottom: -40px;
    right: -30px;
    height: 45px;
  }
}

.Gallery__info {
  margin-bottom: 2rem;
  @include grid-column(math.div(1, 1));
  @include breakpoint-tablet {
    margin-bottom: 0;
    @include grid-column(math.div(6, $galleryFeatures-column-span));
    @include grid-column-offset-left(math.div(1, $galleryFeatures-column-span));
  }
}

.Gallery__image {
  @include grid-column(math.div(1, 1));
  @include breakpoint-tablet {
    @include grid-column(math.div(7, $galleryFeatures-column-span));
    @include grid-column-offset-left(math.div(1, $galleryFeatures-column-span));
    // @include grid-column-offset-right(1 / $galleryFeatures-column-span);
  }
}
