.Button {
  color: $color-text-button;
  background: $background-button;
  font-weight: 900;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  padding: 12px 24px;
  border-radius: 30em;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Button--lg {
  font-size: 1.125rem;
  padding: 16px 32px;
}

.Button--rot2 {
  transform: rotate(-2deg);

  .Button__inner {
    display: inline-block;
    transform: rotate(2deg);
  }
}